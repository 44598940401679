<!--
Used for a custom form component that provides v-model for a complex data structure (e.g. heaters table)
    Usage example:
    - Bind form state (isEditing), model and validation for each input field
      <NumericInput :edit="isEditing" @input="onInput" v-model="model.myDataField" @validation="onValidation('myDataField')" />

    - Override default validation (optional)
      computed: {
        isValid () {
          return this.noInvalidFields && this.model.length > 0
        }
      }
-->
<script>
import { mapGetters, mapActions } from 'vuex'
import _ from 'lodash'

export default {
  props: {
    value: {},
    edit: {
      type: Boolean,
    },
  },

  data() {
    return {
      model: null, // Temporary data model
    }
  },

  computed: {
    ...mapGetters({
      getEditMode: 'form/getEditMode',
      getInvalidFields: 'form/getInvalidFields',
    }),

    noInvalidFields() {
      return this.getInvalidFields.length === 0
    },

    isValid() {
      return this.noInvalidFields
    },

    isEditing() {
      return this.getEditMode
    },
  },

  watch: {
    value() {
      this.resetModel()
    },
  },

  created() {
    this.resetModel()
  },

  methods: {
    ...mapActions({
      setInvalidFields: 'form/setInvalidFields',
    }),

    resetModel() {
      if (this.value) {
        this.model = _.clone(this.value)
      }
    },

    // Handler for edit fields that have validation event
    onValidation(isValid, fieldName) {
      const invalidFields = this.getInvalidFields

      if (isValid) {
        this.setInvalidFields(invalidFields.filter((r) => r !== fieldName))
      } else {
        if (!invalidFields.includes(fieldName)) {
          this.setInvalidFields([...invalidFields, fieldName])
        }
      }
      this.$emit('validation', this.isValid)
    },

    onInput() {
      this.$emit('input', this.model)
      this.$emit('validation', this.isValid)
    },
  },
}
</script>
