<i18n>
{
  "de": {
    "envelopeFormTitle": "Sanierung Gebäudehülle",
    "renovationTypeTitle": "Massnahme",
    "noMeasure": "Keine Renovation",
    "componentTitle": "Komponente",
    "preponeTitle": "Sanierung vorziehen",
    "postponeTitle": "Sanierung aufschieben",
    "yearsLabel": "Jahre (max.)",
    "helpText": "Für die Komponenten der Gebäudehülle können Sie auch einen Dämmstandard festlegen, der durch die Sanierung erzielt werden soll. Die Wärmeverluste reduzieren sich danach entsprechend.",
    "missingRenovationStandardTooltip": "Der Renovationsstandard wurde zwischenzeitlich entfernt."
  }
}
</i18n>

<template>
  <TitleAndContentSection class="c-envelope-form" :title="$t('envelopeFormTitle')">
    <div class="envelope-wrapper">
      <div class="help-text">{{ $t('helpText') }}</div>
      <DetailList has-header>
        <template #header>
          <span>{{ $t('componentTitle') }}</span>
          <span class="align-right">{{ $t('renovationTypeTitle') }}</span>
          <span class="align-right">{{ $t('preponeTitle') }}</span>
          <span class="align-right">{{ $t('postponeTitle') }}</span>
        </template>
        <li v-for="component in envelopeComponents" :key="component.name" class="envelope-row">
          <div>{{ component.title }}</div>
          <Select
            v-if="edit"
            :id="`renovation_type_${component.name}`"
            v-model="model[component.name].renovation_type"
            :options="component.options"
            :clearable="false"
            :reduce="(t) => t.id"
            @input="onInput"
            @validation="onValidation($event, `${component.name}.renovation_type`)"
          />
          <div v-else class="read-only-select">
            {{
              model[component.name].renovation_type == 'NONE' ? $t('noMeasure') : model[component.name].renovation_type
            }}
            <EstimateInput
              v-if="
                !edit &&
                renovationStandardOptions.filter((o) => o.id === model[component.name].renovation_type).length === 0
              "
              v-model="trueModel"
              :tooltip="$t('missingRenovationStandardTooltip')"
            />
          </div>
          <NumericInput
            v-model="model[component.name].prepone_years"
            int
            :edit="isEditing"
            :units="$t('yearsLabel')"
            @input="onInput"
            @validation="onValidation($event, `${component.name}.prepone_years`)"
          />
          <NumericInput
            v-model="model[component.name].postpone_years"
            int
            :edit="isEditing"
            :units="$t('yearsLabel')"
            @input="onInput"
            @validation="onValidation($event, `${component.name}.postpone_years`)"
          />
        </li>
      </DetailList>
    </div>
  </TitleAndContentSection>
</template>

<script>
import ComplexFormStateMixin from '@/components/shared/forms/ComplexFormStateMixin.vue'

import TitleAndContentSection from '@/components/shared/TitleAndContentSection.vue'
import DetailList from '@/components/shared/lists/DetailList.vue'
import NumericInput from '@/components/shared/forms/NumericInput.vue'
import EstimateInput from '@/components/shared/forms/EstimateInput.vue'
import Select from '@/components/shared/forms/Select.vue'

export default {
  mixins: [
    //
    ComplexFormStateMixin,
  ],

  components: {
    TitleAndContentSection,
    DetailList,
    NumericInput,
    EstimateInput,
    Select,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
  },

  computed: {
    trueModel() {
      return true
    },

    envelopeComponents() {
      return [
        {
          name: 'walls',
          title: this.$t('_envelopePlural.walls'),
          options: this.renovationStandardOptions,
        },
        {
          name: 'roof_gable',
          title: this.$t('_envelopePlural.roof_gable'),
          options: this.renovationStandardOptions,
        },
        {
          name: 'roof_flat',
          title: this.$t('_envelopePlural.roof_flat'),
          options: this.renovationStandardOptions,
        },
        {
          name: 'attic',
          title: this.$t('_envelopePlural.attic'),
          options: this.renovationStandardOptions,
        },
        {
          name: 'basement',
          title: this.$t('_envelopePlural.basement'),
          options: this.renovationStandardOptions,
        },
        {
          name: 'windows',
          title: this.$t('_envelopePlural.windows'),
          options: this.renovationStandardOptions,
        },
      ]
    },

    renovationStandardOptions() {
      const options = [{ id: 'NONE', label: this.$t('noMeasure') }]
      this.portfolio.renovation_standards
        .filter((rs) => !rs.building_id)
        .forEach((rs) =>
          options.push({
            id: rs.name,
            label: rs.name, // TOOD: rs.id for id
          })
        )
      return options
    },
  },
}
</script>

<style lang="scss" scoped>
.c-envelope-form {
  padding-bottom: var(--spacing-xl);

  & .align-right {
    text-align: right;
    justify-content: flex-end;
  }

  & .read-only-select {
    padding: 6px; /* workaround to match height of vue-select */
  }

  & .help-text {
    padding: var(--box-padding);
    padding-left: 0;
    margin-right: var(--spacing-l);
  }
}
</style>

<style lang="scss">
.c-envelope-form {
  & .envelope-wrapper {
    display: grid;
    grid-template-columns: 1fr;
  }

  & .detail-list {
    > ul > li {
      grid-template-columns: 1fr 2fr 1fr 1fr;
      text-align: right;
      gap: var(--spacing-l);
      row-gap: var(--spacing-s);
      padding: var(--box-padding);
      align-items: center;
    }

    > ul > li > :first-child {
      text-align: left;
    }
  }
}

@media screen and (min-width: 76rem) {
  .c-envelope-form > .envelope-wrapper {
    grid-template-columns: 1fr 2fr;
  }
}
</style>
