<!--
ApiFormMixin
Requires implementation of the following methods: resetModel, saveHandler, isValid (optional)
Provides: model, isValid, isEditing, formState, onFormEvent, onValidation
-->
<script>
import { mapGetters, mapActions } from 'vuex'
import _ from 'lodash'

export default {
  data() {
    return {
      model: null, // Temporary data model
    }
  },

  computed: {
    ...mapGetters({
      getPortfolioPermission: 'permissions/getPortfolioPermission',
      getBuildingPermission: 'permissions/getBuildingPermission',
      getEditMode: 'form/getEditMode',
      getPending: 'form/getPending',
      getError: 'form/getError',
      getInvalidFields: 'form/getInvalidFields',
    }),

    noInvalidFields() {
      return this.getInvalidFields.length === 0
    },

    isValid() {
      return this.noInvalidFields
    },

    isEditing() {
      return this.getEditMode
    },

    formState() {
      return {
        pending: this.getPending,
        edit: this.getEditMode,
        error: this.getError,
        isValid: this.isValid,
      }
    },
  },

  created() {
    this.resetModel() // Component method
  },

  beforeDestroy() {
    this.resetFormState()
  },

  methods: {
    ...mapActions({
      setEditMode: 'form/setEditMode',
      setPending: 'form/setPending',
      setError: 'form/setError',
      setInvalidFields: 'form/setInvalidFields',
      resetFormState: 'form/resetFormState',
    }),

    // Handler for edit fields that have validation event
    onValidation(isValid, fieldName) {
      const invalidFields = _.clone(this.getInvalidFields)

      if (isValid) {
        this.setInvalidFields(invalidFields.filter((r) => r !== fieldName))
      } else {
        if (!invalidFields.includes(fieldName)) {
          this.setInvalidFields([...invalidFields, fieldName])
        }
      }
    },

    onFormEvent(eventType) {
      if (eventType === 'save') {
        this.private__onSave()
      } else if (eventType === 'cancel') {
        this.setEditMode(false)
        this.resetModel()
        this.$emit('cancel')
        this?.cancelHandler?.()
      } else if (eventType === 'edit') {
        this.setEditMode(true)
      }
    },

    // Handler for save button as a wrapper for saveHandler (which has to be implemented by Mixin container)
    async private__onSave() {
      this.setPending(true)
      try {
        this.setError()
        await this.saveHandler(this.model)
      } catch (error) {
        this.setError(error)
      }
      this.setPending(false)
      this.$emit('save')
      this.setEditMode(false)
    },
  },
}
</script>
