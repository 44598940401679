<!--
ScenarioPageMixin
Loads a scenario for pages with router argument "scenario_id"

Provides: scenario
Requires: PortfolioMixin
-->
<script>
export default {
  props: {
    // Properties set by router:
    scenario_id: {
      type: Number,
      required: true,
    },
  },

  computed: {
    scenario() {
      return (
        this.portfolio !== undefined &&
        this.portfolio.scenarios !== undefined &&
        this.portfolio.scenarios.find((s) => s.id === this.scenario_id)
      )
    },
  },
}
</script>
