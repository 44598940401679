<i18n>
{
  "de": {
    "DISTRICT_feasibility": "Automatische Abklärung (Stadt Zürich, Winterthur, Bern, Genf, Lausanne, Basel, Horgen)",
    "HP_EWS_feasibility": "Automatische Abklärung (ganze Schweiz)",
    "HP_GW_feasibility": "Automatische Abklärung (Kantone ZH, AG)",
    "HP_AIR_feasibility": "Keine automatische Abklärung",
    "PELLET_feasibility": "Keine automatische Abklärung",
    "WOOD_CHIPS_feasibility": "Keine automatische Abklärung",
    "powerLabel": "kW"
  }
}
</i18n>

<template>
  <li class="heating-replacement-row">
    <div class="heating-wrapper">
      <div class="heating-rank">
        <div>{{ rank }}</div>
        <UpDownControls v-if="edit" :no-up="topRow" :no-down="bottomRow" @up="$emit('up')" @down="$emit('down')" />
      </div>
      <div>
        <div>{{ $t(`_heatingTypes.${model.name}`) }}</div>
        <div>{{ $t(`${model.name}_feasibility`) }}</div>
      </div>
    </div>
    <NumericInput
      v-model="model.power_min"
      optional
      :edit="isEditing"
      :units="$t('powerLabel')"
      @input="onInput"
      @validation="onValidation($event, 'power_min')"
    />
    <NumericInput
      v-model="model.power_max"
      optional
      :edit="isEditing"
      :units="$t('powerLabel')"
      @input="onInput"
      @validation="onValidation($event, 'power_max')"
    />
  </li>
</template>

<script>
import ComplexFormMixin from '@/components/shared/forms/ComplexFormMixin.vue'

import UpDownControls from '@/components/shared/UpDownControls.vue'
import NumericInput from '@/components/shared/forms/NumericInput.vue'

export default {
  mixins: [
    //
    ComplexFormMixin,
  ],

  components: {
    UpDownControls,
    NumericInput,
  },

  props: {
    rank: {
      type: Number,
    },
    topRow: {
      type: Boolean,
      default: false,
    },
    bottomRow: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss">
.heating-replacement-row {
  display: contents;

  & .heating-wrapper {
    display: grid;
    grid-template-columns: 100px 1fr;
  }

  & .heating-rank {
    display: grid;
    grid-template-columns: 1fr 2fr;
    align-items: center;
    margin-right: var(--spacing-m);
  }

  & > :first-child {
    grid-column-start: 1;
  }
}
</style>
