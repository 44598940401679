// snake case for backwards compatibility
export default class Scenario {
  constructor(scenario) {
    this.envelope = {
      attic: {
        postpone_years: scenario?.envelope?.attic?.postpone_years || null,
        prepone_years: scenario?.envelope?.attic?.prepone_years || null,
        renovation_type: scenario?.envelope?.attic?.renovation_type || null,
        uvalue: scenario?.envelope?.attic?.uvalue || null,
      },
      basement: {
        postpone_years: scenario?.envelope?.basement?.postpone_years || null,
        prepone_years: scenario?.envelope?.basement?.prepone_years || null,
        renovation_type: scenario?.envelope?.basement?.renovation_type || null,
        uvalue: scenario?.envelope?.basement?.uvalue || null,
      },
      roof_flat: {
        postpone_years: scenario?.envelope?.roof_flat?.postpone_years || null,
        prepone_years: scenario?.envelope?.roof_flat?.prepone_years || null,
        renovation_type: scenario?.envelope?.roof_flat?.renovation_type || null,
        uvalue: scenario?.envelope?.roof_flat?.uvalue || null,
      },
      roof_gable: {
        postpone_years: scenario?.envelope?.roof_gable?.postpone_years || null,
        prepone_years: scenario?.envelope?.roof_gable?.prepone_years || null,
        renovation_type: scenario?.envelope?.roof_gable?.renovation_type || null,
        uvalue: scenario?.envelope?.roof_gable?.uvalue || null,
      },
      walls: {
        postpone_years: scenario?.envelope?.walls?.postpone_years || null,
        prepone_years: scenario?.envelope?.walls?.prepone_years || null,
        renovation_type: scenario?.envelope?.walls?.renovation_type || null,
        uvalue: scenario?.envelope?.walls?.uvalue || null,
      },
      windows: {
        postpone_years: scenario?.envelope?.windows?.postpone_years || null,
        prepone_years: scenario?.envelope?.windows?.prepone_years || null,
        renovation_type: scenario?.envelope?.windows?.renovation_type || null,
        uvalue: scenario?.envelope?.windows?.uvalue || null,
      },
    }
    this.heating = {
      ignored_replacements: scenario?.heating?.ignored_replacements || [],
      postpone_years: scenario?.heating?.postpone_years || null,
      prepone_years: scenario?.heating?.prepone_years || null,
      replacements: scenario?.heating?.replacements || [
        {
          name: 'DISTRICT',
          power_max: null,
          power_min: null,
        },
        {
          name: 'HP_EWS',
          power_max: null,
          power_min: null,
        },
        {
          name: 'HP_AIR',
          power_max: null,
          power_min: null,
        },
        {
          name: 'HP_GW',
          power_max: null,
          power_min: null,
        },
        {
          name: 'PELLET',
          power_max: null,
          power_min: null,
        },
        {
          name: 'WOOD_CHIPS',
          power_max: null,
          power_min: null,
        },
      ],
    }
    this.pv = {
      dimensioning: {
        coverage_target: scenario?.pv?.dimensioning?.coverage_target || null,
        fassade_fraction: scenario?.pv?.dimensioning?.fassade_fraction || null,
        fassade_min_modules: scenario?.pv?.dimensioning?.fassade_min_modules || null,
        fassade_min_yield: scenario?.pv?.dimensioning?.fassade_min_yield || null,
        roof_fraction: scenario?.pv?.dimensioning?.roof_fraction || null,
        roof_max_shape_factor: scenario?.pv?.dimensioning?.roof_max_shape_factor || null,
        roof_min_modules: scenario?.pv?.dimensioning?.roof_min_modules || null,
        roof_min_yield: scenario?.pv?.dimensioning?.roof_min_yield || null,
        use_fassade: scenario?.pv?.dimensioning?.use_fassade || null,
      },
      timing: {
        heating_replacement: scenario?.pv?.timing?.heating_replacement || null,
        immediately: scenario?.pv?.timing?.immediately || null,
        replace_existing: scenario?.pv?.timing?.replace_existing || null,
        roof_renovation: scenario?.pv?.timing?.roof_renovation || null,
      },
    }
  }
}
