<!--
Edit form for PvScenarioSchema
TODO: #refactoring and split into smaller components
-->
<i18n>
{
  "de": {
    "dimensioningTitle": "Dimensionierung",
    "pvFormTitle": "Ausbau Photovoltaik",
    "timingTitle": "Zeitpunkt Installation Photovoltaik",
    "withHeatingReplacementEnabled": "Beim Ersatz von Heizungen",
    "withRoofRenovationEnabled": "Bei Dachsanierungen",
    "replaceExistingEnabled": "Beim Ersatz bestehender PV-Anlagen",
    "immediatelyEnabled": "Sofort",
    "noPvLabel": "Kein Ausbau der Photovoltaik",
    "helpText": "Der CO₂mpass wählt für jede Liegenschaft automatisch die am besten geeigneten Dachflächen aus und dimensioniert die Anlage gemäss den von Ihnen festgelegten Regeln."
  }
}
</i18n>

<template>
  <TitleAndContentSection class="c-pv-form" :title="$t('pvFormTitle')">
    <div class="pv-wrapper">
      <div class="help-text">{{ $t('helpText') }}</div>
      <section class="pv-form-wrapper">
        <DetailList>
          <DetailListItem :title="$t('timingTitle')">
            <div v-if="edit">
              <CheckInput
                v-model="model.timing.heating_replacement"
                :edit="isEditing"
                :label="$t('withHeatingReplacementEnabled')"
                @input="onInput"
              />
              <br />
              <CheckInput
                v-model="model.timing.roof_renovation"
                :edit="isEditing"
                :label="$t('withRoofRenovationEnabled')"
                @input="onInput"
              />
              <br />
              <CheckInput
                v-model="model.timing.replace_existing"
                :edit="isEditing"
                :label="$t('replaceExistingEnabled')"
                @input="onInput"
              /><br />
              <CheckInput
                v-model="model.timing.immediately"
                :edit="isEditing"
                :label="$t('immediatelyEnabled')"
                @input="onInput"
              />
            </div>
            <div v-else-if="timingActive">
              <div v-if="model.timing.heating_replacement">{{ $t('withHeatingReplacementEnabled') }}</div>
              <div v-if="model.timing.roof_renovation">{{ $t('withRoofRenovationEnabled') }}</div>
              <div v-if="model.timing.replace_existing">{{ $t('replaceExistingEnabled') }}</div>
              <div v-if="model.timing.immediately">{{ $t('immediatelyEnabled') }}</div>
            </div>
            <div v-else>
              {{ $t('noPvLabel') }}
            </div>
          </DetailListItem>
          <DetailListItem v-if="timingActive" :title="$t('dimensioningTitle')">
            <EditPvDimensioning v-model="model.dimensioning" :edit="isEditing" @input="onDimensioningChange" />
          </DetailListItem>
        </DetailList>
      </section>
    </div>
  </TitleAndContentSection>
</template>

<script>
import ComplexFormMixin from '@/components/shared/forms/ComplexFormMixin.vue'

import TitleAndContentSection from '@/components/shared/TitleAndContentSection.vue'
import DetailList from '@/components/shared/lists/DetailList.vue'
import DetailListItem from '@/components/shared/lists/DetailListItem.vue'
import CheckInput from '@/components/shared/forms/CheckInput.vue'
import EditPvDimensioning from '@/components/building/measures/edit/EditPvDimensioning.vue'

export default {
  mixins: [
    //
    ComplexFormMixin,
  ],

  components: {
    EditPvDimensioning,
    TitleAndContentSection,
    CheckInput,
    DetailList,
    DetailListItem,
  },

  computed: {
    timingActive() {
      return (
        this.model.timing.heating_replacement ||
        this.model.timing.roof_renovation ||
        this.model.timing.replace_existing ||
        this.model.timing.immediately
      )
    },
  },

  methods: {
    onDimensioningChange(dimensioning) {
      this.model.dimensioning = dimensioning
      this.onInput()
    },
  },
}
</script>

<style lang="scss" scoped>
.c-pv-form {
  padding-bottom: var(--spacing-xl);

  & .help-text {
    padding: var(--box-padding);
    padding-left: 0;
    margin-right: var(--spacing-l);
  }
}
</style>

<style lang="scss">
.c-pv-form {
  padding-bottom: var(--spacing-xl);

  > .pv-wrapper {
    display: grid;
    grid-template-columns: 1fr;
  }
}

@media screen and (min-width: 76rem) {
  .c-pv-form > .pv-wrapper {
    grid-template-columns: 1fr 2fr;
  }
}
</style>
