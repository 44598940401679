<i18n>
{
  "de": {
    "heatingFormTitle": "Ersatz von Gas- und Ölheizungen",
    "technologyPriority": "Priorisierung Technologien",
    "ignoredHeatingFormsTitle": "Nicht vorgesehen",
    "noneSelected": "Kein Ersatz von Gas- und Ölheizungen vorgesehen",
    "noneIgnored": "-",
    "preponeTitle": "Ersatz vorziehen",
    "postponeTitle": "Ersatz aufschieben",
    "powerMinTitle": "Leistungsuntergrenze",
    "powerMaxTitle": "Leistungsobergrenze",
    "yearsLabel": "Jahre (max.)",
    "helpText": "Über eine Prioritätenliste können Sie festlegen, welche Wärmeerzeuger beim Heizungsersatz bevorzugt werden sollen. Beim Ersatz wird dann die am höchsten priorisierte, am Standort verfügbare Technologie ausgewählt. Ersetzt werden nur Öl- und Gasheizungen. Andere Energieträger werden beibehalten, die Heizung jedoch durch ein neueres Modell ersetzt."
  }
}
</i18n>

<template>
  <TitleAndContentSection class="c-heating-form" :title="$t('heatingFormTitle')">
    <div class="heating-wrapper">
      <div class="help-text">{{ $t('helpText') }}</div>
      <section class="heating-form-wrapper">
        <DetailList>
          <DetailListItem :title="$t('preponeTitle')">
            <NumericInput
              v-model="model.prepone_years"
              int
              :edit="isEditing"
              :units="$t('yearsLabel')"
              @input="onInput"
              @validation="onValidation($event, 'prepone_years')"
            />
          </DetailListItem>
          <DetailListItem :title="$t('postponeTitle')">
            <NumericInput
              v-model="model.postpone_years"
              int
              :edit="isEditing"
              :units="$t('yearsLabel')"
              @input="onInput"
              @validation="onValidation($event, 'postpone_years')"
            />
          </DetailListItem>
        </DetailList>
        <br />
        <DetailList has-header class="priorities">
          <template #header>
            <span>{{ $t('technologyPriority') }}</span>
            <span class="align-right">{{ $t('powerMinTitle') }}</span>
            <span class="align-right">{{ $t('powerMaxTitle') }}</span>
          </template>
          <HeatingReplacementRow
            v-for="(r, i) in model.replacements"
            :key="r.item.name"
            v-model="r.item"
            :rank="i + 1"
            :edit="isEditing"
            :top-row="i === 0"
            @input="onInput"
            @validation="onValidation($event, r.item.name)"
            @up="moveUp(i)"
            @down="moveDown(i)"
          />
          <li v-if="model.replacements.length === 0" class="heating-replacement-row none-selected">
            <div>{{ $t('noneSelected') }}</div>
            <div></div>
            <div></div>
          </li>
        </DetailList>

        <br />
        <h4>{{ $t('ignoredHeatingFormsTitle') }}</h4>
        <DetailList class="priorities">
          <HeatingReplacementRow
            v-for="(r, i) in model.ignored_replacements"
            :key="r.item.name"
            v-model="r.item"
            :edit="isEditing"
            :bottom-row="true"
            @input="onInput"
            @validation="onValidation($event, r.item.name)"
            @up="moveIgnoredUp(i)"
            @down="moveIgnoredDown(i)"
          />
          <li v-if="model.ignored_replacements.length === 0" class="heating-replacement-row">
            {{ $t('noneIgnored') }}
          </li>
        </DetailList>
      </section>
    </div>
  </TitleAndContentSection>
</template>

<script>
import ComplexFormStateMixin from '@/components/shared/forms/ComplexFormStateMixin.vue'

import TitleAndContentSection from '@/components/shared/TitleAndContentSection.vue'
import HeatingReplacementRow from '@/components/scenarios/edit/HeatingReplacementRow.vue'
import DetailList from '@/components/shared/lists/DetailList.vue'
import DetailListItem from '@/components/shared/lists/DetailListItem.vue'
import NumericInput from '@/components/shared/forms/NumericInput.vue'

const swapArrayElements = (a, x, y) => {
  if (a.length === 1) {
    return a
  }
  a.splice(y, 1, a.splice(x, 1, a[y])[0])
  return a
}

export default {
  mixins: [ComplexFormStateMixin],
  components: {
    HeatingReplacementRow,
    TitleAndContentSection,
    DetailList,
    DetailListItem,
    NumericInput,
  },
  methods: {
    resetModel() {
      // Overwrites ComplexFormMixin to add 'item' wrapper
      if (this.value) {
        this.model = JSON.parse(JSON.stringify(this.value))
        this.model.replacements = this.model.replacements.map((r) => {
          return {
            item: r,
          }
        })
        this.model.ignored_replacements = this.model.ignored_replacements.map((r) => {
          return {
            item: r,
          }
        })
      }
    },
    onInput() {
      // Overwrites ComplexFormMixin to remove 'item' wrapper
      this.$emit('input', {
        prepone_years: this.model.prepone_years,
        postpone_years: this.model.postpone_years,
        replacements: this.model.replacements.map((r) => r.item),
        ignored_replacements: this.model.ignored_replacements.map((r) => r.item),
      })
      this.$emit('validation', this.isValid)
    },
    moveUp(i) {
      if (this.model.replacements && i > 0) {
        this.model.replacements = swapArrayElements(this.model.replacements, i, i - 1)
      }
      this.onInput()
    },
    moveDown(i) {
      if (this.model.replacements) {
        const lastIndex = this.model.replacements.length - 1
        if (i < lastIndex) {
          this.model.replacements = swapArrayElements(this.model.replacements, i, i + 1)
        } else if (i === lastIndex) {
          const item = this.model.replacements.pop()
          this.model.ignored_replacements = [item, ...this.model.ignored_replacements]
        }
      }
      this.onInput()
    },
    moveIgnoredUp(i) {
      if (this.model.ignored_replacements) {
        const item = this.model.ignored_replacements.splice(i, 1)[0]
        this.model.replacements = [...this.model.replacements, item]
      }
      this.onInput()
    },
    moveIgnoredDown(i) {
      if (this.model.ignored_replacements && i < this.model.ignored_replacements.length - 1) {
        this.model.ignored_replacements = swapArrayElements(this.model.ignored_replacements, i, i + 1)
      }
      this.onInput()
    },
  },
}
</script>

<style lang="scss" scoped>
.c-heating-form {
  padding-bottom: var(--spacing-xl);

  & .align-right {
    text-align: right;
    justify-content: flex-end;
  }

  & .help-text {
    padding: var(--box-padding);
    padding-left: 0;
    margin-right: var(--spacing-l);
  }
}
</style>

<style lang="scss">
.c-heating-form {
  > .heating-wrapper {
    display: grid;
    grid-template-columns: 1fr;
  }

  & .detail-list.priorities {
    > ul > li {
      grid-template-columns: 1fr 170px 170px;
      text-align: right;
      gap: var(--spacing-l);
      row-gap: var(--spacing-s);
      padding: var(--box-padding);
      align-items: center;
    }

    > ul > li > :first-child {
      text-align: left;
    }
  }
}

@media screen and (min-width: 76rem) {
  .c-heating-form > .heating-wrapper {
    grid-template-columns: 1fr 2fr;
  }
}
</style>
