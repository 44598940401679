<template>
  <section class="up-down-controls">
    <span v-if="noDown" />
    <a v-else href="#" @click.prevent="$emit('down')"><img src="/icons/arrow-down-circle.svg" /></a>
    <span v-if="noUp" />
    <a v-else href="#" @click.prevent="$emit('up')"><img src="/icons/arrow-up-circle.svg" /></a>
  </section>
</template>

<script>
export default {
  props: {
    noUp: {
      type: Boolean,
      default: false,
    },
    noDown: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style>
.up-down-controls {
  align-self: center;
  display: grid;
  grid-template-columns: repeat(2, 50%);
}

.up-down-controls a {
  display: grid;
  place-items: center;
}

.up-down-controls img {
  max-width: 100%;
  opacity: 0.7;
}

.up-down-controls a:hover img {
  opacity: 1;
}
</style>
