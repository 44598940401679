<i18n>
{
  "de": {
    "scenarioName": "Szenarioname"
  }
}
</i18n>

<template>
  <FormRow v-if="edit" :label="$t('scenarioName')" class="c-edit-description">
    <TextInput
      v-model="model"
      :min-length="1"
      :edit="isEditing"
      @input="onInput"
      @validation="onValidation($event, 'description')"
    />
  </FormRow>
</template>

<script>
import ComplexFormStateMixin from '@/components/shared/forms/ComplexFormStateMixin.vue'

import FormRow from '@/components/shared/forms/FormRow.vue'
import TextInput from '@/components/shared/forms/TextInput.vue'

export default {
  mixins: [
    //
    ComplexFormStateMixin,
  ],

  components: {
    FormRow,
    TextInput,
  },

  props: {
    edit: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped>
.c-edit-description {
  max-width: 50%;
  padding-bottom: var(--spacing-m);
}
</style>
